
import { mixins, Options, Vue } from "vue-class-component";
import { MenuInterface } from "@/types";
import { menuModule } from "@/store";
import { copy, search } from "@/utils";
import { useToast } from "balm-ui";
import { SortableMixin } from "@/mixins";
import PageWrapper from "@/layout/pageWrapper/index.vue";
import ZeroState from "@/components/ui/zeroState/index.vue";

@Options({
  name: "MenuList",
  components: { PageWrapper, ZeroState },
})
export default class MenuList extends mixins(SortableMixin) {
  private thead: any = [
    {
      value: "Id",
      sort: "asc",
      columnId: "id",
    },
    "Title",
    "Link",
    "Actions",
  ];
  private tbody: any = [
    {
      field: "id",
      width: 100,
      fixed: "left",
    },
    "title",
    {
      field: "link",
      width: 400,
    },
    {
      width: 100,
      slot: "actions",
    },
  ];
  private selectedRows: Array<number> = [];
  private tData: MenuInterface[] = [];
  private activeRowData!: MenuInterface | null;
  private actionType = "Create";
  protected page = 1;
  private readonly itemsToShowPerPage = 10;
  private open = false;
  private isLoading = true;

  private get isSubRoute(): boolean {
    const subRoutes = ["Create", "Edit"];

    return this.$route.matched.some(({ name }: { name: any }) =>
      subRoutes.some((sRoute: string) => name.indexOf(sRoute) > -1)
    );
  }

  private get getMenu(): MenuInterface[] {
    if (!menuModule.getMenu) return [];
    return copy(menuModule.getMenu);
  }

  private get tableData(): MenuInterface[] {
    return this.tData;
  }

  private set tableData(val: MenuInterface[]) {
    this.tData = val;
  }

  private get getMenuLength(): number {
    return this.getMenu.length;
  }

  private getAssetsItemsPerPage(
    page: number,
    source: MenuInterface[]
  ): MenuInterface[] {
    return source.slice(
      (page - 1) * this.itemsToShowPerPage,
      page * this.itemsToShowPerPage
    );
  }

  private editRow(data: any): void {
    this.activeRowData = data;
    this.onModalOpen("Edit");
  }

  private deleteRow(data: any): void {
    this.activeRowData = data;
    this.onModalOpen("Delete");
  }

  private onModalOpen(type: string): void {
    this.open = !this.open;
    this.actionType = type;
  }

  private onConfirm(result: any): void {
    const typeToRedirect = ["Create", "Edit"];
    const $toast = useToast();

    if (result) {
      if (typeToRedirect.includes(this.actionType)) {
        let routerData = { name: `Menu/${this.actionType}` };

        if (this.activeRowData) {
          routerData = { ...routerData, ...{ params: this.activeRowData } };
        }
        this.$router.push(routerData);
      } else {
        menuModule.Delete(this.activeRowData?.id);
        $toast(`${this.activeRowData?.title} is deleted!`);
      }

      this.activeRowData = null;
    }
  }

  private onDeleteSelectedRows(selectedRows: Array<number>): void {
    console.log(this.getMenu.filter((c, i) => selectedRows.includes(i + 1)));
  }

  private onSearch(str: string): void {
    const result = search({ sourceData: this.getMenu, str });
    this.tableData = this.getAssetsItemsPerPage(this.page, result);
  }

  created(): void {
    menuModule.Load();
  }

  mounted(): void {
    menuModule.$watch(
      (module) => module.getMenu,
      () => {
        this.isLoading = false;
        this.tableData = this.getAssetsItemsPerPage(this.page, this.getMenu);

        this.$nextTick(() => this.onListSort(menuModule));
      }
    );

    this.emitter.on("search", (val: string) => this.onSearch(val));
  }
}


import { mixins, Options, Vue } from "vue-class-component";
import { copy, search } from "@/utils";
import { useToast } from "balm-ui";
import { LanguagesInterface } from "@/types";
import { languagesModule } from "@/store";
import { SortableMixin } from "@/mixins";
import PageWrapper from "@/layout/pageWrapper/index.vue";
import ZeroState from "@/components/ui/zeroState/index.vue";

@Options({
  name: "LanguagesList",
  components: { PageWrapper, ZeroState },
})
export default class LanguagesList extends mixins(SortableMixin) {
  private thead: any = [
    {
      value: "Id",
      sort: "asc",
      columnId: "id",
    },
    "Name",
    "Code",
    "Actions",
  ];
  private tbody: any = [
    {
      field: "id",
      width: 80,
      fixed: "left",
    },
    "name",
    {
      field: "code",
      width: 100,
    },
    {
      width: 100,
      slot: "actions",
    },
  ];
  private selectedRows: Array<number> = [];
  private tData: LanguagesInterface[] = [];
  private activeRowData!: LanguagesInterface | null;
  private actionType = "Create";
  protected page = 1;
  private readonly itemsToShowPerPage = 10;
  private open = false;

  private get isSubRoute(): boolean {
    const subRoutes = ["Create", "Edit"];

    return this.$route.matched.some(({ name }: { name: any }) =>
      subRoutes.some((sRoute: string) => name.indexOf(sRoute) > -1)
    );
  }

  private get getLanguages(): LanguagesInterface[] {
    return copy(languagesModule.getLanguages);
  }

  private get tableData(): LanguagesInterface[] {
    return this.tData;
  }

  private set tableData(val: LanguagesInterface[]) {
    this.tData = val;
  }

  private get getLanguagesLength(): number {
    return this.getLanguages.length;
  }

  private getAssetsItemsPerPage(
    page: number,
    source: LanguagesInterface[]
  ): LanguagesInterface[] {
    return source.slice(
      (page - 1) * this.itemsToShowPerPage,
      page * this.itemsToShowPerPage
    );
  }

  private editRow(data: any): void {
    this.activeRowData = data;
    this.onModalOpen("Edit");
  }

  private deleteRow(data: any): void {
    this.activeRowData = data;
    this.onModalOpen("Delete");
  }

  private onModalOpen(type: string): void {
    this.open = !this.open;
    this.actionType = type;
  }

  private onConfirm(result: any): void {
    const typeToRedirect = ["Create", "Edit"];
    const $toast = useToast();

    if (result) {
      if (typeToRedirect.includes(this.actionType)) {
        let routerData = { name: `Languages/${this.actionType}` };

        if (this.activeRowData) {
          routerData = { ...routerData, ...{ params: this.activeRowData } };
        }

        this.$router.push(routerData);
      } else {
        languagesModule.Delete(this.activeRowData?.id);
        $toast(`${this.activeRowData?.name} is deleted!`);
      }

      this.activeRowData = null;
    }
  }

  private onSearch(str: string): void {
    const result = search({ sourceData: this.getLanguages, str });
    this.tableData = this.getAssetsItemsPerPage(this.page, result);
  }

  private onDeleteSelectedRows(selectedRows: Array<number>): void {
    console.log(
      this.getLanguages.filter((c, i) => selectedRows.includes(i + 1))
    );
  }

  created(): void {
    languagesModule.Load();
  }

  mounted(): void {
    languagesModule.$watch(
      (module) => module.getLanguages,
      () => {
        this.tableData = this.getAssetsItemsPerPage(
          this.page,
          this.getLanguages
        );

        this.$nextTick(() => this.onListSort(languagesModule));
      }
    );

    this.emitter.on("search", (val: string) => this.onSearch(val));
  }
}
